import React, { useState, useEffect } from 'react';
import Logo from "../assets/logo.svg";
import '../styles/App.css';

function App() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isMuted, setIsMuted] = useState(false);

  useEffect(() => {
    const audio = document.getElementById('background-music');
    audio.volume = 0.5;
  }, []);

  const togglePlayAndMute = () => {
    const audio = document.getElementById('background-music');

    if (!isPlaying) {
      audio.currentTime = 14; // Commencer à 14 secondes
      audio.play().then(() => {
        setIsPlaying(true);
      }).catch((error) => {
        console.log("Lecture bloquée ou erreur : ", error);
      });
    } else {
      // Si l'audio est en cours de lecture, on gère le mute/unmute
      audio.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <img 
          src={Logo} 
          className="App-logo" 
          alt="logo"
        />
        <img
          src="https://firebasestorage.googleapis.com/v0/b/beybladeespace-86bb4.appspot.com/o/site%2Fbanji-chibi.webp?alt=media"
          alt="banji chibi qui tourne"
          className="Banji"
          onClick={togglePlayAndMute}
        />
        <p className="desc">
          Soon
        </p>

        <audio id="background-music" loop>
          <source src="op.mp3" type="audio/mpeg" />
          Votre navigateur ne supporte pas l'élément audio.
        </audio>

        {/* Si la musique joue, montrer l'état actuel (muted ou unmuted) */}
        {isPlaying && (
          <p>
            {isMuted ? '🔇 Son désactivé' : '🔊 Son activé'}
          </p>
        )}
      </header>
    </div>
  );
}

export default App;
